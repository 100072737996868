<template>
  <button
    ref="clickBtn"
    class="button-icon"
    :class="[
      color,
      size,
      { muted: isMuted, round, square },
      { 'square-gray': color === 'gray-light' },
      { disabled: unclickable },
    ]"
    :disabled="disabled"
    @click="clicked"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'HccButtonIcon',
  props: {
    color: {
      type: String,
      default: 'secondary',
    },
    muted: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    square: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
    unclickable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ripples: [],
    };
  },
  methods: {
    clicked(e) {
      try {
        e.target?.blur();
        const button = e.target.closest('button');
        button?.blur();
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    isMuted() {
      return this.muted && !this.round && !this.square;
    },
  },
};
</script>
