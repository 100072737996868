export default () => {
  return {
    path: '/team',
    component: () => import('@modules/DefaultView.vue'),
    meta: {
      forAuth: true,
      site: {
        t: 'app.monitoring',
        name: 'app.monitoring',
      },
    },
    children: [
      {
        path: '',
        name: 'team',
        component: () => import('@modules/monitoring/views/Team.vue'),
        meta: {
          forAuth: true,
          site: {
            name: 'app.team',
          },
          requiredPermissions: ['team_info_card', 'team_read', 'team_disconnect_user'],
        },
      },
    ]
  };
};
