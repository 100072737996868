import byCampaign from '@/graphql/queries/campaigns/dataBank/byCampaign.gql';
import listTemplatesGql from '@/graphql/queries/listTemplates.gql';
import executeQuery from '@/utils/gql-api';
import errorHandler from '@/utils/error.handler';
import { apolloClient } from '@/vue-apollo';

export default {
  fetchDataBank({ commit }, { id, type }) {
    apolloClient.query({
      query: byCampaign,
      variables: {
        id,
        type,
      },
    }).then(({ data }) => {
      commit('setDataBank', { type, payload: data.dataBank });
    })
      .catch((err) => {
        commit('setDataBank', { type, payload: [] });
        errorHandler.logErrors(err);
      });
  },
  async fetchTemplates({ commit }, { campaignId, channelId }) {
    if (!campaignId && !channelId) {
      return;
    }

    const data = {
      ...(campaignId && { campaign_id: campaignId }),
      ...(channelId && { channel_id: channelId }),
    };

    try {
      const payload = await executeQuery('listTemplates', listTemplatesGql, data);
      commit('setTemplates', payload);
    } catch (error) {
      errorHandler.logErrors(error);
    }
  },
  updateDataBank({ commit, getters }, res) {
    const dataBank = getters.getDataBank;
    const index = dataBank[res.type].findIndex((d) => d.id === res.id);
    commit('updateDataBank', { payload: res, index });
  },
  addDataBank({ commit, getters }, payload) {
    const dataBank = [...getters.getTabSection(payload.type)];
    dataBank.push(payload);
    commit('setDataBank', { type: payload.type, payload: dataBank });
  },
  deleteData({ commit, getters }, { res, type }) {
    const dataBank = getters.getDataBank;
    const index = dataBank[type].findIndex((d) => d.id === res.id);
    commit('deleteDataBank', { index, type });
  },
  deleteTemplate({ commit, getters }, { id }) {
    const templates = getters.getTemplates;
    const filterDeleted = templates.filter((templ) => templ.id !== id);
    commit('setTemplates', filterDeleted);
  },
  addTemplate({ commit }, payload) {
    commit('addTemplate', payload);
  },
  clearDatabank({ commit }) {
    commit('clear');
  },
};
