export const getters = {
  items: (state) => state.items,
  item: (state) => (id) => {
    if (id) {
      return state.items.find((item) => item.id === id) || {};
    }
    return {};
  },
  total: (state) => state.total,
  currentPage: (state) => state.currentPage,
  filters: (state) => state.filters,
  step: (state) => (id) => {
    if (id) {
      return state.steps[id] ? state.steps[id] : {};
    }
    return {};
  },
  stepChild: (state) => (id) => {
    if (id) {
      return state.steps[id] ? state.steps[id] : [];
    }
    return [];
  },
  // parent: (state) => (id) => {
  //   if (id) {
  //     return state.parents[id] ? state.parents[id] : {}
  //   }
  //   return {}
  // },
  // chatBotDetail: (state) => (id) => {
  //   if (id) {
  //     return state.chatBotDetails[id] ? state.chatBotDetails[id] : {}
  //   }
  //   return {}
  // },
};
