import { createApp } from 'vue';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { createVfm } from 'vue-final-modal';
import VueFileAgentNext from '@boindil/vue-file-agent-next';
import Vue3Toastify, { toast } from 'vue3-toastify';
import vClickOutside from 'v-click-outside';
import Clipboard from 'vue-clipboard2';
import { datadogRum } from '@datadog/browser-rum';
// import * as TgsPlayer from '@lottiefiles/lottie-player/dist/tgs-player';
import App from './App.vue';
import { apolloAnalyticsClient, apolloClient, createProvider } from './vue-apollo';
import router from './router';
import store from './store';
import { i18n } from './i18n';
import mixins from './mixins';
import filters from './utils/filters';
import { auth } from './utils/auth';
import mitt from 'mitt';
import { ApolloClients } from '@vue/apollo-composable';
import FloatingVue, { vTooltip, vClosePopper } from 'floating-vue';
import registerIcons from '@/icons';
import loadModules from '@modules';
import { createDeviceDetector } from '@/utils/device.detector';

const routerApp = loadModules(router, i18n);
const vfm = createVfm();
const emitter = mitt();
const app = createApp(App);

const { bind, unbind } = vClickOutside.directive;
app.directive('click-outside', {
  mounted(el, bindling) {
    bind(el, { value: bindling.value });
  },
  beforeUnmount(el) {
    unbind(el);
  },
});

// Config
app.config.productionTip = import.meta.env.VITE_PRODUCTIONTIP || false;
app.config.globalProperties.emitter = emitter;

// Directives
FloatingVue.options.themes.tooltip.hideTriggers = events => [...events];
app.directive('auth', auth);
app.directive('tooltip', vTooltip);
app.directive('close-popper', vClosePopper);

// Mixins
app.mixin(mixins);

// Plugins
app.use(createProvider());
app.provide(ApolloClients, {
  default: apolloClient,
  secondary: apolloAnalyticsClient,
});
app.use(routerApp);
app.use(Clipboard);
app.use(store);
app.use(i18n);
app.use(filters);
app.use(vfm);
app.use(VueFileAgentNext);
// app.use(TgsPlayer);
app.use(createDeviceDetector());

// Global properties
registerIcons(app);

// Google Maps
app.use(VueGoogleMaps, {
  load: {
    key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
  },
});

const toastOptions = {
  autoClose: 5000,
  theme: 'colored',
  clearOnUrlChange: false,
};
app.use(Vue3Toastify, toastOptions);
app.config.globalProperties.$toasted = {
  global: {
    info: (message) => toast.info(
      message, { ...toastOptions }
    ),
    success: (message) => toast.success(
      message, { ...toastOptions }
    ),
    error: (message) => toast.error(
      message, { ...toastOptions }
    ),
  }
};

const isProduction = import.meta.env.VITE_DD_ENVIRONMENT === 'production';

if (isProduction) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DD_APPLICATIONID,
    clientToken: import.meta.env.VITE_DD_CLIENTTOKEN,
    site: 'datadoghq.com',
    service: import.meta.env.VITE_DD_SERVICE,
    env: import.meta.env.VITE_DD_ENVIRONMENT,
    version: import.meta.env.PACKAGE_VERSION,
    defaultPrivacyLevel: 'mask-user-input',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 25,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  });
}

app.mount('#app');
