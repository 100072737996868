export default (i18n) => {
  return {
    path: '/messages',
    name: 'messages',
    component: () => import('@modules/messages/views/Messages.vue'),
    meta: {
      forAuth: true,
      site: {
        name: i18n.global.t('app.messages'),
        t: 'app.messages',
      },
      requiredPermissions: ['messages_change_status', 'messages_read', 'messages_report'],
    },
  };
};
