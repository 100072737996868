export const UPDATE_CAMPAIGN = 'update_campaign';
export const UPDATE_CAMPAIGN_LIST = 'update-campaign-list';
export const UPDATE_CAMPAIGN_ERROR = 'update-campaign-error';
export const UPDATE_CAMPAIGN_DONE = 'update-campaign-done';
export const UPDATE_DATA_BANK = 'update-data-bank';
export const DATA_BANK_SUCCESS = 'update-data-bank-success';
export const CREATE_DATA_BANK = 'create-data-bank';
export const CREATE_TEMPLATE = 'create-template';
export const DELETE_TEMPLATE = 'delete-template';
export const REQUEST_UPLOAD = 'request-upload-done';
export const OPEN_USER_MENU = 'open-user-menu';
export const AUDIO_PAUSE_ALL = 'audio_pause_all';
export const AUDIO_STOP_ALL = 'audio_stop_all';
export const CAROUSEL_HIDE = 'carousel_hide';
export const CAROUSEL_OPEN = 'carousel_open';
export const CONVERSATION_LOAD_MESSAGES = 'conversation_load_messages';
export const MESSAGES_LOADED = 'messages_loaded';
export const LOCALE_LAGUAGE_CHANGE = 'update_moment_locale';
export const REPORT_MESSAGES_REQUEST = 'report_messages_request';
export const UPDATE_USERS_OWNERS = 'update_users_owners';
export const UPDATE_COMPANY_USERS = 'update_company_users';
export const NEW_USER = 'new_user';

export const MODAL_TEMPLATE_CLOSE = 'close-template-modal';
