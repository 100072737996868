// import Vue from 'vue';
import axios from 'axios';
// import VueAxios from 'vue-axios';
import { configuration, configService, token } from './api-config';

// Vue.use(VueAxios, axios);

const apiService = axios.create({
  baseURL: configService.apiUrl,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

apiService.interceptors.request.use(
  (config) => configuration(config),
  (error) => {
    Promise.reject(error.response || error.message);
  },
);

apiService.getPayment = (paymentId, queryParams = {}) => apiService.get(
  `${configService.coreUrl}payment/${paymentId}`,
  { params: queryParams },
);

apiService.getDollarRate = () => apiService.get(`${configService.coreUrl}payment/dollar-rate`);

apiService.getInvoicesUpcoming = invoiceData => apiService.post(
  `${configService.coreUrl}payment/invoices-upcoming`,
  invoiceData,
);

apiService.fetchCompanyData = companyId => apiService.get(`${configService.apiUrl}company/${companyId}`);

apiService.updateCompanyLogo = (id, logoUrl, companyName) => apiService.post(`${configService.apiUrl}company`, {
  id,
  name: companyName,
  logo: logoUrl,
});

apiService.updateUserProfile = (userData) => {
  return apiService.put(`${configService.apiUrl}users/profile`, userData);
};

apiService.manageSubscription = (subscriptionDetails) => {
  const {
    action,
    subscriptionId,
    subscriptionItemId,
    licenses,
    newTierId,
    tierId,
    cardId,
    companyId,
  } = subscriptionDetails;
  return apiService.post(`${configService.coreUrl}payment/subscriptions`, {
    action,
    subscriptionId,
    subscriptionItemId,
    licenses,
    newTierId,
    tierId,
    cardId,
    companyId,
  });
};

apiService.payNow = ({ paymentId, stripeSourceId }) => {
  return apiService.post(`${configService.coreUrl}payment/invoices/pay`, {
    paymentId,
    stripeSourceId,
  });
};

export default apiService;
