import 'moment/dist/locale/es';
import moment from 'moment-timezone';
import messages from '@intlify/unplugin-vue-i18n/messages';
import momentDurationFormat from 'moment-duration-format';
import { createI18n } from 'vue-i18n';

momentDurationFormat(moment);
moment.locale('es');

export const i18n = createI18n({
  locale: 'es',
  fallbackLocale: 'es',
  messages,
});

export function setI18nLanguage(lang) {
  i18n.global.locale.value = lang;

  return lang;
}
