<template>
  <div class="vfm__header">
    <div class="heading h4" :class="variant">
      {{ title }}
    </div>
    <hcc-button-icon
      v-if="variant === 'error'"
      round
      class="confirmation__header--icon"
      color="alert"
      unclickable
    >
      <Icon icon="mdi:delete" class="icon x4" />
    </hcc-button-icon>
    <hcc-button-icon
      v-else-if="variant === 'info'"
      round
      class="confirmation__header--icon"
      color="primary"
      unclickable
    >
      <Icon icon="mdi:information-variant" class="icon x4" />
    </hcc-button-icon>
    <hcc-button-icon
      v-else-if="variant === 'unlink'"
      round
      class="confirmation__header--icon"
      color="alert"
      unclickable
    >
      <Icon icon="mdi:link-variant-off" class="icon x4" />
    </hcc-button-icon>
    <hcc-button-icon
      v-else
      round
      class="confirmation__header--icon"
      color="secondary"
      unclickable
    >
      <Icon icon="mdi:exclamation-thick" class="icon x4" />
    </hcc-button-icon>
  </div>
</template>

<script>
import HccButtonIcon from '../HccButtonIcon/index.vue';
import { Icon } from '@iconify/vue';

export default {
  components: {
    Icon,
    HccButtonIcon,
  },
  props: {
    title: {
      type: String,
    },
    variant: {
      type: String,
    },
  },

};
</script>
