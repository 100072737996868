export class ChatBot {
  id;
  name;
  company;
  entityId;
  active;
  coreUrl;

  constructor(data) {
    this.id = data._id || null;
    this.name = data.name || null;
    this.company = data.company;
    this.entityId = data.entityId || null;
    this.coreUrl = data.coreUrl || null;
    this.active = data.active;
    this.position = data.position;
    this.conversation = data.conversation || {};
  }
}
