export const state = {
  total: 0,
  currentPage: 1,
  items: [],
  steps: {},
  parent: {},
  optionSteps: [],
  filters: {
    company: null,
    campaign: null,
  },
  // company: null,
  // campaign: null,
};
