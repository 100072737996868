<template>
  <VueFinalModal
    :modal-id="name"
    :click-to-close="false"
    content-class="confirmation"
    :content-style="{
      height: 'auto',
      width: '400px',
    }"
    @before-open="beforeOpen"
  >
    <hcc-confirmation-header
      :variant="variant"
      :title="title"
    />

    <div class="vfm__body" v-html="description" />

    <slot name="footer">
      <hcc-confirmation-footer
        :show-cancel="showCancel"
        :variant="variant"
        :handle-confirm="handleConfirm"
        :handle-cancel="handleCancel"
      />
    </slot>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';
import HccConfirmationHeader from './HccConfirmationHeader.vue';
import HccConfirmationFooter from './HccConfirmationFooter.vue';

export default {
  components: {
    VueFinalModal,
    HccConfirmationFooter,
    HccConfirmationHeader,
  },
  props: {
    name: {
      type: String,
      default: 'confirmation',
    },
    title: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'default',
    },
    description: {
      type: String,
      default: '',
    },
    confirmFunction: {
      type: Function,
    },
    cancelFunction: {
      type: Function,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      params: {},
    };
  },
  methods: {
    beforeOpen(event) {
      this.params = { cancel: () => {}, confirm: () => {}, ...(event.params || {}) };
    },
    handleConfirm() {
      // this.params.confirm();
      // if (this.confirmFunction) {
      //   this.confirmFunction();
      // }
      // this.$vfm.close(this.name);
      this.confirmFunction();
    },
    handleCancel() {
      this.params.cancel();
      if (this.cancelFunction) {
        this.cancelFunction();
      }
      this.$vfm.close(this.name);
    },
  },

};
</script>
