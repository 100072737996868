export default (i18n) => {
  return {
    path: '/reports',
    name: 'reports',
    component: () => import('@modules/reports/views/Reports.vue'),
    meta: {
      forAuth: true,
      site: {
        name: i18n.global.t('app.reports'),
        t: 'app.reports'
      },
      requiredPermissions: ['reports_generate', 'reports_programmer', 'messages_report'],
    },
  };
};
