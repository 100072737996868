export default (i18n) => {
  return {
    path: '/campaigns',
    name: 'module-campaign',
    component: () => import('@modules/DefaultView.vue'),
    meta: {
      forAuth: true,
      site: {
        name: i18n.global.t('app.campaigns'),
        t: 'app.campaigns',
      },
    },
    children: [
      {
        path: '',
        name: 'campaigns',
        component: () => import('@modules/campaigns/views/List.vue'),
        meta: {
          forAuth: true,
          requiredPermissions: [
            'campaigns_read',
            'campaigns_write',
            'campaigns_blocked_clients',
            'config_campaign_channels_read',
            'config_campaign_channels_write',
            'config_campaign_assigned_admin_read',
            'config_campaign_assigned_admin_write',
            'config_campaign_assigned_agent_read',
            'config_campaign_assigned_agent_write',
            'config_campaign_categories_write',
            'config_campaign_categories_read',
            'config_campaign_labels_read',
            'config_campaign_labels_write',
            'config_campaign_automatic_messages_read',
            'config_campaign_automatic_messages_write',
            'config_campaign_schedules_write',
            'config_campaign_schedules_read',
            'config_campaign_options_read',
            'config_campaign_options_write',
            'config_campaign_options_bots',
            'config_campaign_options_transfer_agent',
            'config_campaign_options_message_multimedia',
            'config_campaign_options_transfer_campaigns',
            'config_campaign_fast_text_write',
            'config_campaign_fast_image_write',
            'config_campaign_fast_location_read',
            'config_campaign_fast_location_write',
            'config_campaign_fast_video_read',
            'config_campaign_fast_video_write',
            'config_campaign_fast_templates',
            'config_campaign_fast_text_read',
            'config_campaign_fast_image_read',
          ],
        },
      },
      {
        path: '/campaigns/:campaignId',
        component: () => import('@modules/campaigns/views/Campaign.vue'),
        meta: {
          forAuth: true,
          site: {
            name: i18n.global.t('app.campaign-settings'),
            t: (store, { campaignId }) => {
              const { campaigns } = store.getters['shared/getCurrentCompany'];

              return campaigns.find(({ id }) => id === campaignId)?.name || '-';
            },
          },
          requiredPermissions: [
            'config_campaign_channels_read',
            'config_campaign_channels_write',
            'config_campaign_assigned_admin_read',
            'config_campaign_assigned_admin_write',
            'config_campaign_assigned_agent_read',
            'config_campaign_assigned_agent_write',
            'config_campaign_categories_write',
            'config_campaign_categories_read',
            'config_campaign_labels_read',
            'config_campaign_labels_write',
            'config_campaign_automatic_messages_read',
            'config_campaign_automatic_messages_write',
            'config_campaign_schedules_write',
            'config_campaign_schedules_read',
            'config_campaign_options_read',
            'config_campaign_options_write',
            'config_campaign_options_bots',
            'config_campaign_options_transfer_agent',
            'config_campaign_options_message_multimedia',
            'config_campaign_options_transfer_campaigns',
            'config_campaign_fast_text_write',
            'config_campaign_fast_image_write',
            'config_campaign_fast_location_read',
            'config_campaign_fast_location_write',
            'config_campaign_fast_video_read',
            'config_campaign_fast_video_write',
            'config_campaign_fast_templates',
            'config_campaign_fast_text_read',
            'config_campaign_fast_image_read',
          ],
        },
        children: [
          {
            path: '',
            redirect: { name: 'campaigns-channels-settings' },
          },
          {
            path: 'settings',
            component: () => import('@modules/campaigns/views/Settings.vue'),
            meta: {
              site: { t: 'app.campaign-settings' },
            },
            children: [
              {
                path: 'channels',
                name: 'campaign-settings-channels',
                meta: {
                  site: { t: 'app.channels' },
                },
                component: () => import('@modules/campaigns/views/Settings/ChannelsTab.vue'),
              },
              {
                path: 'users',
                name: 'campaign-settings-users',
                meta: {
                  site: {
                    t: 'app.users',
                  },
                },
                component: () => import('@modules/campaigns/views/Settings/UsersTab.vue'),
              },
              {
                path: 'categories',
                name: 'campaign-settings-categories',
                meta: {
                  site: {
                    t: 'configuration.tabs.categories',
                  },
                },
                component: () => import('@modules/campaigns/views/Settings/CategoriesTab.vue'),
              },
              {
                path: 'labels',
                name: 'campaign-settings-labels',
                meta: {
                  site: {
                    t: 'configuration.tabs.labels',
                  },
                },
                component: () => import('@modules/campaigns/views/Settings/LabelsTab.vue'),
              },
              {
                path: 'messages',
                name: 'campaign-settings-messages',
                meta: {
                  site: {
                    t: 'configuration.tabs.automatic-messages',
                  },
                },
                component: () => import('@modules/campaigns/views/Settings/MessagesTab.vue'),
              },
              {
                path: 'schedule',
                name: 'campaign-settings-schedule',
                meta: {
                  site: {
                    t: 'configuration.tabs.schedule',
                  },
                },
                component: () => import('@modules/campaigns/views/Settings/ScheduleTab.vue'),
              },
              {
                path: 'options',
                name: 'campaign-settings-options',
                meta: {
                  site: {
                    t: 'configuration.tabs.options',
                  },
                },
                component: () => import('@modules/campaigns/views/Settings/OptionsTab.vue'),
              },
              {
                path: 'data-bank',
                name: 'campaign-settings-data-bank',
                meta: {
                  site: {
                    t: 'app.data-bank',
                  },
                },
                component: () => import('@modules/campaigns/views/Settings/DataBankTab.vue'),
              },
            ],
          },
          {
            path: 'blocked-clients',
            name: 'campaign-blocked-clients',
            component: () => import('@modules/campaigns/views/BlockedClients.vue'),
            meta: {
              forAuth: true,
              meta: { layout: 'navigation-layout' },
              site: {
                t: 'app.blocked-clients',
              },
              requiredPermissions: ['campaigns_blocked_clients'],
            },
          },
        ],
      },
    ],
  };
};
