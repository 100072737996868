export default () => {
  return {
    path: '/surveys',
    component: () => import('@modules/chatbots/views/ChatBotInitialView.vue'),
    requiredPermissions: ['surveys_read', 'surveys_write'],
    meta: {
      site: {
        t: 'app.surveys',
      },
      requiredPermissions: ['surveys_read', 'surveys_write'],
    },
    children: [
      {
        path: '',
        name: 'surveys',
        component: () => import('@modules/surveys/views/IndexView.vue'),
      },
      {
        path: ':id',
        name: 'survey-show',
        component: () => import('@modules/surveys/views/QuestionsView.vue'),
        meta: {
          group: 'surveys',
          requiredPermissions: ['surveys_read', 'surveys_write'],
        },
      },
    ],
  };
};
