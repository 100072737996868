import axios from 'axios';
import { AUTH_TOKEN, OWNER } from '@/constants';

export const shared = {
  namespaced: true,
  state: {
    user: {},
    currentCompanyID: null,
    companies: [],
    lang: navigator.language.split('-')[0],
    accessToken: localStorage.getItem(AUTH_TOKEN),
    logged: true,
    sideBarState: 'close',
    roles: [],
    plugins: [],
  },
  getters: {
    getAccessToken(state) {
      return state.accessToken;
    },
    userInfo(state) {
      return state.user;
    },
    getCurrentCompany(state) {
      return (state.companies?.fullCompanies || state.companies)
        .find(({ id }) => id === state.currentCompanyID) || null;
    },
    allChannelsByCompany(state, getters) {
      return getters.getCurrentCompany?.campaigns.reduce((obj, { channels }) => [...obj, ...channels], []);
    },
    getCompanies(state) {
      return state.companies;
    },
    getFilterCompanies(state) {
      const { role = '' } = state.user || {};

      return (state.companies?.fullCompanies || state.companies)
        .filter(({ maxUsers }) => OWNER !== role || maxUsers > 0);
    },
    isOwner(state) {
      return state.user.role.includes('owner');
    },
    isSuper(state) {
      return state.user.role.includes('super');
    },
    getLocale(state) {
      return state.lang;
    },
    isLogged(state) {
      return state.logged;
    },
    getUserRole(state) {
      return state.user.role;
    },
    getRoles(state) {
      return state.roles;
    },
    getLanguage(state) {
      return state.lang;
    },
    getPlugins(state) {
      return state.plugins;
    },
    getSideBarState(state) {
      return state.sideBarState;
    },
  },
  mutations: {
    setAccessToken(state, payload) {
      state.accessToken = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setCompanies(state, payload) {
      state.companies = payload;
    },
    setCurrentCompanyID(state, payload) {
      state.currentCompanyID = payload;
    },
    setLang(state, lang) {
      state.lang = lang;
    },
    setLogged(state, logged) {
      state.logged = logged;
    },
    logout(state) {
      state.companies = [];
    },
    setRoles(state, data) {
      state.roles = data;
    },
    setPlugins(state, data) {
      state.plugins = data;
    },
    setSidebarState(state, data) {
      state.sideBarState = data;
    },
  },
  actions: {
    async fetchUserInfo({ state, commit }, params) {
      try {
        const { data } = await axios.get(
          `${import.meta.env.VITE_API_AUTH_URL}/api/v1/users/auth`,
          {
            headers: {
              Authorization: `Bearer ${params.token || state.accessToken}`,
            },
          },
        );

        if (data?.id && data.roles?.length > 0 && data.permissions && data.permissionsBlocked) {
          const { roles, ...user } = data;
          commit('setUser', {
            ...user,
            role: roles[0],
          });

          return true;
        }
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  },
};
