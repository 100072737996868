<template>
  <div class="vfm__footer">
    <hcc-button
      v-if="showCancel"
      size="sm"
      variant="outline"
      color="muted"
      @click="handleCancel"
    >
      {{ $t("shared-components.confirmation.cancel") }}
    </hcc-button>
    <hcc-button
      size="sm"
      class="confirmation__buttons--button"
      :color="getButtonColor"
      data-test-id="modal-confirm"
      @click="handleConfirm"
    >
      {{ $t("shared-components.confirmation.confirm") }}
    </hcc-button>
  </div>
</template>

<script>
import HccButton from '../HccButton/index.vue';

export default {
  components: {
    HccButton,
  },
  props: {
    variant: {
      type: String,
      default: 'alert',
    },
    handleConfirm: {
      type: Function,
    },
    handleCancel: {
      type: Function,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getButtonColor() {
      let color = 'secondary';

      if (this.variant === 'error' || this.variant === 'unlink') {
        color = 'alert';
      } else if (this.variant === 'info') {
        color = 'primary';
      }
      return color;
    },
  },
};
</script>
