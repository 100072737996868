import FileTree from 'vue-material-design-icons/FileTree.vue';
import AccountMultipleIcon from 'vue-material-design-icons/AccountMultiple.vue';
import AutorenewIcon from 'vue-material-design-icons/Autorenew.vue';
import ArrowLeftIcon from 'vue-material-design-icons/ArrowLeft.vue';
import ClockOutlineIcon from 'vue-material-design-icons/ClockOutline.vue';
import CloseCircleIcon from 'vue-material-design-icons/CloseCircle.vue';
import CompareIcon from 'vue-material-design-icons/Compare.vue';
import DotsHorizontalCircleIcon from 'vue-material-design-icons/DotsHorizontalCircle.vue';
import DownloadIcon from 'vue-material-design-icons/Download.vue';
import FileDocumentIcon from 'vue-material-design-icons/FileDocument.vue';
import FileIcon from 'vue-material-design-icons/File.vue';
import FilterVariantIcon from 'vue-material-design-icons/FilterVariant.vue';
import ForumIcon from 'vue-material-design-icons/Forum.vue';
import FileExcelIcon from 'vue-material-design-icons/FileExcel.vue';
import HeartIcon from 'vue-material-design-icons/Heart.vue';
import InformationVariantIcon from 'vue-material-design-icons/InformationVariant.vue';
import LabelIcon from 'vue-material-design-icons/Label.vue';
import MapMarkerIcon from 'vue-material-design-icons/MapMarker.vue';
import MessageIcon from 'vue-material-design-icons/Message.vue';
import MessageTextIcon from 'vue-material-design-icons/MessageText.vue';
import PaletteSwatchIcon from 'vue-material-design-icons/PaletteSwatch.vue';
import PencilIcon from 'vue-material-design-icons/Pencil.vue';
import SendIcon from 'vue-material-design-icons/Send.vue';
import TextSubjectIcon from 'vue-material-design-icons/TextLong.vue';
import WrenchIcon from 'vue-material-design-icons/Wrench.vue';
import WindowMinimizeIcon from 'vue-material-design-icons/WindowMinimize.vue';
import DatabaseIcon from 'vue-material-design-icons/Database.vue';
import Poll from 'vue-material-design-icons/Poll.vue';
import MoreIcon from 'vue-material-design-icons/DotsVertical.vue';

export default function registerIcons(app) {
  app.component('FileTreeIcon', FileTree);
  app.component('AccountMultipleIcon', AccountMultipleIcon);
  app.component('AutorenewIcon', AutorenewIcon);
  app.component('ArrowLeftIcon', ArrowLeftIcon);
  app.component('ClockOutlineIcon', ClockOutlineIcon);
  app.component('CloseCircleIcon', CloseCircleIcon);
  app.component('CompareIcon', CompareIcon);
  app.component('DatabaseIcon', DatabaseIcon);
  app.component('DotsHorizontalCircleIcon', DotsHorizontalCircleIcon);
  app.component('DownloadIcon', DownloadIcon);
  app.component('FileIcon', FileIcon);
  app.component('FileDocumentIcon', FileDocumentIcon);
  app.component('FilterVariantIcon', FilterVariantIcon);
  app.component('FileExcel', FileExcelIcon);
  app.component('ForumIcon', ForumIcon);
  app.component('Heart', HeartIcon);
  app.component('InformationVariantIcon', InformationVariantIcon);
  app.component('LabelIcon', LabelIcon);
  app.component('MapMarkerIcon', MapMarkerIcon);
  app.component('MessageIcon', MessageIcon);
  app.component('MessageTextIcon', MessageTextIcon);
  app.component('MoreIcon', MoreIcon);
  app.component('PaletteSwatchIcon', PaletteSwatchIcon);
  app.component('PencilIcon', PencilIcon);
  app.component('SendIcon', SendIcon);
  app.component('TextSubjectIcon', TextSubjectIcon);
  app.component('WrenchIcon', WrenchIcon);
  app.component('WindowMinimizeIcon', WindowMinimizeIcon);
  app.component('PollIcon', Poll);
}
