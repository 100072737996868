export const mutations = {
  SET_ITEMS(state, items) {
    state.items = items;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage;
  },
  REMOVE_ITEM(state, id) {
    const indexItem = state.items.findIndex(item => item.id === id);
    if (indexItem > -1) {
      state.items.splice(indexItem, 1);
    }
  },
  validCompany(state, companies = []) {
    if (!state.company) {
      return false;
    }

    return companies.some(({ id }) => id === state.company);
  },
};
